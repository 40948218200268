import React from "react"
import * as S from "./styled"
import { GridContainer } from "@styled"
import Menu from "./menu/Menu"
import Contact from "./contact/Contact"
import Address from "./address/Address"
import Socials from "./socials/Socials"
import Form from "./form/Form"

const Presentation = ({
    id,
    footermenu,
    contact,
    address,
    socials,
    form,
    copyrights,
}: Props) => {
    return (
        <S.Footer id={id}>
            <GridContainer>
                <div className="grid md:grid-cols-12">
                    <S.Wrapper className="col-span-12 md:col-span-6">
                        <S.Logo />
                        <Menu
                            title={footermenu.title}
                            items={footermenu.items}
                        />

                        <Contact data={contact} />
                        <Address items={address} />
                        <Socials items={socials} />
                    </S.Wrapper>
                    <div className="col-span-12 md:col-span-6">
                        <Form data={form} />
                    </div>
                </div>
            </GridContainer>
            <S.Copyrights>
                <GridContainer>
                    <S.Paragraph
                        dangerouslySetInnerHTML={{ __html: copyrights }}
                    />
                </GridContainer>
            </S.Copyrights>
        </S.Footer>
    )
}

interface Props {
    id: string
    contact: {
        email: string
        name: string
        title: string
        image: any
    }
    address: Array<{
        content: string
        title: string
    }>
    footermenu: {
        title: string
        items: Array<{
            page: {
                title: string
                url: string
                target: string
            }
        }>
    }
    socials: Array<{
        icon: string
        link: {
            target: string
            title: string
            url: string
        }
    }>
    form: {
        header: string
        subheader: string
        text: string
        privacy: string
        getform: string
        errortext: string
        successtext: string
    }
    copyrights: string
}

export default Presentation
