import styled, { css } from "styled-components"
import { Link as ScrollLink } from "react-scroll"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const Title = styled.p`
    color: ${cssVariables.colors.primary.secondary};
`

const List = styled.ul`
    margin-top: 60px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;

    ${breakpoints.md} {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 4;
    }
`

const Item = styled.li`
    line-height: 32px;
`

const styles = css`
    width: 100%;
    font-family: ${cssVariables.fontFamily.primary};
    font-weight: 100;
    font-size: ${cssVariables.fonts.base};
    cursor: pointer;

    &.active,
    &:hover {
        color: ${cssVariables.colors.primary.light};
    }
`

const Link = styled(ScrollLink)`
    ${styles}
`

const ExternalLink = styled.a`
    ${styles}
`

export { Title, List, Item, Link, ExternalLink }
