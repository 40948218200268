import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const GridContainer = styled.div`
    max-width: ${cssVariables.gridWidth};
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;

    ${breakpoints.lg} {
        padding: 0 40px;
    }
`

export default GridContainer
