import styled from "styled-components"
import { cssVariables } from "@cssVariables"
import { breakpoints } from "@breakpoints"

const Wrapper = styled.div`
    position: relative;
    background-color: ${cssVariables.colors.dark.secondary};
    min-height: 100%;
    z-index: 2;
    max-width: calc(100vw - 40px);

    &:after {
        content: "";
        position: absolute;
        width: 100vw;
        height: 100%;
        top: 0;
        left: -20px;
        background: ${cssVariables.colors.dark.secondary};
        z-index: -1;

        ${breakpoints.md} {
            left: 0;
        }
    }

    ${breakpoints.md} {
        padding: 40px 30px 0;
        width: initial;
    }

    & h4 {
        margin-bottom: 5px;
    }
`

const Paragraph = styled.div`
    font-family: ${cssVariables.fontFamily.secondary};
    font-size: ${cssVariables.fonts.medium};
`

const Form = styled.form`
    margin-top: 25px;
    font-size: ${cssVariables.fonts.small};
`

const Group = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
`

const Message = styled.div`
    display: flex;
    flex-direction: column;

    & textarea {
        background: transparent;
        border: 1px solid ${cssVariables.colors.gray.dark};
        color: ${cssVariables.colors.primary.white};
        border-radius: 15px;
        min-height: 75px;
        padding-left: 15px;
        outline: none;

        &:placeholder {
            color: ${cssVariables.colors.gray.main};
            opacity: 1;
        }

        &:focus {
            background: ${cssVariables.colors.dark.main};
        }

        &:-webkit-autofill,
        &:focus:-webkit-autofill {
            background-color: transparent !important;
            -webkit-box-shadow: 0 0 0 50px ${cssVariables.colors.dark.secondary}
                inset;
            -webkit-text-fill-color: ${cssVariables.colors.primary.white};
        }
    }
`

const Privacy = styled.div`
    font-size: ${cssVariables.fonts.small};
    line-height: ${cssVariables.fonts.large};
    width: 100%;
    margin-top: 15px;

    ${breakpoints.md} {
        max-width: 70%;
    }

    & a {
        color: ${cssVariables.colors.primary.light};
    }
`

export { Wrapper, Paragraph, Form, Group, Message, Privacy }
