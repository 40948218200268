import React from "react"
import * as S from "./styled"

const Input = ({
    label,
    name,
    type,
    placeholder,
    required,
    minLength,
    id,
}: Props) => {
    return (
        <S.Input>
            <label>{label}</label>
            <input
                type={type}
                name={name}
                required={required}
                placeholder={placeholder}
                minLength={minLength}
                data-testid={id}
            />
        </S.Input>
    )
}

interface Props {
    label: string
    name: string
    type: string
    placeholder: string
    required?: boolean
    minLength?: number
    id?: string
}

export default Input
