import React from "react"
import * as S from "./styled"
import { H5 } from "@headers"
import { cssVariables } from "@cssVariables"

const Address = ({ items }: Props) => {
    return (
        <S.Wrapper>
            {items?.map(({ content, title }, index) => (
                <S.Item key={index}>
                    <H5
                        content={title}
                        color={cssVariables.colors.primary.secondary}
                    />
                    <S.Paragraph
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                </S.Item>
            ))}
        </S.Wrapper>
    )
}

interface Props {
    items: Array<{
        content: string
        title: string
    }>
}

export default Address
