import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const Wrapper = styled.div`
    margin-top: 40px;
    display: flex;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;

    ${breakpoints.md} {
        grid-column-start: 3;
        grid-column-end: 7;
        grid-row-start: 3;
        grid-row-end: 4;
    }
`

const Item = styled.div`
    &:nth-child(2) {
        margin-left: 35px;
    }

    ${breakpoints.lg} {
        &:nth-child(2) {
            margin-left: 75px;
        }
    }
`

const Paragraph = styled.div`
    & p {
        font-size: ${cssVariables.fonts.base};
        font-family: ${cssVariables.fontFamily.primary};
    }
`

export { Wrapper, Item, Paragraph }
