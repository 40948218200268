import React from "react"
import { Helmet } from "react-helmet"

const Seo = ({ title, description }: Props) => {
    return (
        <Helmet
            htmlAttributes={{
                lang: "pl-PL",
            }}
            title={title}
            meta={[
                {
                    name: "description",
                    content: description,
                },
            ]}
        />
    )
}

interface Props {
    title: string
    description: string
}

export default Seo
