import React from "react"
import * as S from "./styled"
import { H3, H5 } from "@headers"
import { cssVariables } from "@cssVariables"
import { gatsbyImage } from "@types"
import { GatsbyImage } from "gatsby-plugin-image"

const Contact = ({ data: { email, name, title, image } }: Props) => {
    return (
        <S.Wrapper className="md:col-span-4">
            <H5 content={title} color={cssVariables.colors.primary.secondary} />

            <S.Container>
                <S.Image>
                    <GatsbyImage
                        image={image.localFile.childImageSharp.gatsbyImageData}
                        alt=""
                    />
                </S.Image>
                <S.Content>
                    <H3 content={name} color={"#fff"} />
                    <S.Email
                        href={`mailto:${email}`}
                        dangerouslySetInnerHTML={{
                            __html: email,
                        }}
                    />
                </S.Content>
            </S.Container>
        </S.Wrapper>
    )
}

interface Props {
    data: {
        email: string
        name: string
        title: string
        image: gatsbyImage
    }
}

export default Contact
