import React from "react"
import * as S from "./styled"
import { H4, H2 } from "@headers"
import { cssVariables } from "@cssVariables"

const Heading = ({
    backgroundText,
    subheader,
    header,
    position = "center",
    light,
    offset,
    backgroundColor,
}: Props) => {
    return (
        <S.Wrapper position={position}>
            <S.Headers position={position}>
                <H4
                    color={
                        light
                            ? cssVariables.colors.dark.light
                            : cssVariables.colors.primary.main
                    }
                    content={subheader}
                />
                <H2 color={light ? "#fff" : "#000"} content={header} />
            </S.Headers>

            <S.Background
                shadowColor={light ? "#fff" : "#000"}
                light={light}
                $offset={offset}
                color={backgroundColor}
                position={position}
                dangerouslySetInnerHTML={{ __html: backgroundText }}
            />
        </S.Wrapper>
    )
}

interface Props {
    backgroundText: string
    subheader: string
    header: string
    position?: "center" | "left"
    light?: true
    offset?: true
    backgroundColor?: string
}

export default Heading
