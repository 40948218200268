import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const Wrapper = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background: ${cssVariables.gradient.vertical};
    cursor: pointer;

    ${breakpoints.md} {
        display: none;
    }
`

const Line = styled.span<{ active: boolean }>`
    width: 25px;
    height: 2px;
    margin: 2px 0;
    background-color: #fff;
    transition: 0.3s;
    border-radius: 5px;

    &:nth-child(1) {
        transform: ${({ active }) =>
            active ? "translateX(20px)" : "translateX(0)"};
        opacity: ${({ active }) => (active ? "0" : "1")};
    }

    &:nth-child(2) {
        transform: ${({ active }) =>
            active ? "rotate(45deg)" : "rotate(0deg)"};
        margin-bottom: -4px;
    }

    &:nth-child(3) {
        transform: ${({ active }) =>
            active ? "rotate(-45deg)" : "rotate(0deg)"};
    }
    &:nth-child(4) {
        transform: ${({ active }) =>
            active ? "translateX(-20px)" : "translateX(0)"};
        opacity: ${({ active }) => (active ? "0" : "1")};
    }
`

const Caption = styled.span`
    display: inline-block;
    margin-top: 10px;
    font-size: ${cssVariables.fonts.small};
    font-weight: bold;
    color: #fff;
`

export { Wrapper, Line, Caption }
