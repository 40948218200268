import styled, { keyframes } from "styled-components"
import { cssVariables } from "@cssVariables"

const MessageWrapper = styled.div`
    margin: 20px;
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Loader = styled.div<{ background?: string; small?: true }>`
    margin: ${({ small }) => (small ? "0 auto" : "50px auto")};
    width: ${({ small }) => (small ? "30px" : "70px")};
    height: ${({ small }) => (small ? "30px" : "70px")};
    border-radius: 50%;
    background: linear-gradient(
        to right,
        ${cssVariables.colors.primary.light} 10%,
        rgba(255, 255, 255, 0) 42%
    );
    position: relative;
    animation: ${Spin} 1.4s infinite linear;
    transform: translateZ(0);

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 50%;
        background: ${cssVariables.colors.primary.light};
        border-radius: 100% 0 0 0;
    }

    &:after {
        content: "";
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: ${({ background }) =>
            background ?? cssVariables.colors.primary.white};
        width: 75%;
        height: 75%;
        border-radius: 50%;
    }
`

const Message = styled.p<{ color?: string }>`
    font-family: ${cssVariables.fontFamily.secondary};
    font-size: ${cssVariables.fonts.medium};
    text-align: center;
    color: ${({ color }) =>
        color ? color : cssVariables.colors.primary.light};
`

export { MessageWrapper, Loader, Message }
