import styled from "styled-components"
import { cssVariables } from "@cssVariables"
import { breakpoints } from "@breakpoints"

const Wrapper = styled.div`
    margin: 60px 60px 0 0;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(218, 218, 218, 0.15);
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;

    ${breakpoints.sm} {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
    }

    ${breakpoints.md} {
        grid-column-start: 3;
        grid-column-end: 7;
    }
`
const Image = styled.div`
    width: 90px;

    & img {
        border-radius: 15px;
    }
`

const Email = styled.a`
    color: ${cssVariables.colors.primary.light};
    font-size: ${cssVariables.fonts.base};
`

const Container = styled.div`
    margin-top: 18px;
    display: flex;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 18px;
    color: ${cssVariables.colors.primary.light};
    font-size: ${cssVariables.fonts.base};

    & h3 {
        margin-bottom: 0;
        margin-top: 12px;
    }

    & a {
        margin-top: -10px;
    }
`

export { Wrapper, Container, Image, Content, Email }
