import React from "react"

const Facebook = (): JSX.Element => {
    return (
        <svg width="20.3" height="20.315" viewBox="0 0 20.3 20.315">
            <g transform="translate(314.648 373.347)">
                <path
                    className="a"
                    d="M-301.313-394.042v-8.938h2.49l.41-3.09H-301.3c0-.35,0-.681,0-1.011,0-.292,0-.584,0-.876a.8.8,0,0,1,.8-.9,6.646,6.646,0,0,1,.711-.021c.435,0,.87,0,1.323,0,0-.057.009-.1.009-.151,0-.945,0-1.891,0-2.836,0-.12-.029-.161-.156-.16-1.054,0-2.11-.025-3.163.01a3.291,3.291,0,0,0-2.929,1.671,2.71,2.71,0,0,0-.344,1.382c0,.911,0,1.822,0,2.732,0,.131-.034.168-.166.166-.49-.008-.98,0-1.47,0h-.174v3.094h1.8v8.936c-.052,0-.1.008-.157.008q-3.609,0-7.217,0a2.133,2.133,0,0,1-2.215-2.22q0-7.967,0-15.934a2.13,2.13,0,0,1,1.876-2.142,2.277,2.277,0,0,1,.3-.014h15.949a2.13,2.13,0,0,1,2.178,2.181q0,3.089,0,6.178,0,4.878,0,9.757a2.125,2.125,0,0,1-2.151,2.193c-1.569.011-3.138,0-4.708,0C-301.235-394.035-301.264-394.039-301.313-394.042Z"
                    transform="translate(0 41)"
                />
            </g>
        </svg>
    )
}

const Instagram = (): JSX.Element => {
    return (
        <svg width="21.027" height="21.02" viewBox="0 0 21.027 21.02">
            <g transform="translate(657.518 354.686)">
                <path
                    className="a"
                    d="M-657.517-344.2q0-3.643,0-7.285a2.957,2.957,0,0,1,3.186-3.2q7.313,0,14.625,0a2.965,2.965,0,0,1,3.214,3.224q0,7.31,0,14.619a2.954,2.954,0,0,1-3.164,3.173q-7.362,0-14.723,0a2.942,2.942,0,0,1-3.137-3.148Q-657.522-340.508-657.517-344.2ZM-647-337.706a6.494,6.494,0,0,0,6.468-6.447,6.491,6.491,0,0,0-6.473-6.489,6.493,6.493,0,0,0-6.469,6.445A6.5,6.5,0,0,0-647-337.706Zm7.994-13.277a1.487,1.487,0,0,0-1.478-1.514,1.491,1.491,0,0,0-1.5,1.5,1.508,1.508,0,0,0,1.457,1.493A1.5,1.5,0,0,0-639.01-350.982Z"
                    transform="translate(0 0)"
                />
                <path
                    className="a"
                    d="M-598.532-298.873a3.15,3.15,0,0,1,3.143,3.213,3.152,3.152,0,0,1-3.2,3.106,3.154,3.154,0,0,1-3.12-3.189A3.153,3.153,0,0,1-598.532-298.873Z"
                    transform="translate(-48.457 -48.462)"
                />
            </g>
        </svg>
    )
}

const Linkedin = (): JSX.Element => {
    return (
        <svg width="20.3" height="20.3" viewBox="0 0 20.3 20.3">
            <path
                className="a"
                d="M21.044,3A2.256,2.256,0,0,1,23.3,5.256V21.044A2.256,2.256,0,0,1,21.044,23.3H5.256A2.256,2.256,0,0,1,3,21.044V5.256A2.256,2.256,0,0,1,5.256,3H21.044m-.564,17.481V14.5A3.677,3.677,0,0,0,16.8,10.827a3.268,3.268,0,0,0-2.616,1.466V11.041H11.041v9.44h3.147v-5.56a1.573,1.573,0,1,1,3.147,0v5.56h3.147M7.376,9.27A1.9,1.9,0,1,0,5.47,7.376,1.895,1.895,0,0,0,7.376,9.27m1.568,11.21v-9.44H5.819v9.44Z"
                transform="translate(-3 -3)"
            />
        </svg>
    )
}

export { Facebook, Instagram, Linkedin }
