import styled from "styled-components"
import { Link as ScrollLink } from "react-scroll"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const List = styled.ul<{ active: boolean }>`
    position: absolute;
    top: 80px;
    left: 0;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    padding: 0 20px;
    width: 100vw;
    background-color: #fff;
    z-index: 100;
    transform: ${({ active }) =>
        active ? "translateX(0)" : "translateX(-100%)"};
    transition: 0.3s;
    opacity: ${({ active }) => (active ? 1 : 0)};

    ${breakpoints.md} {
        position: static;
        flex-direction: row;
        width: auto;
        height: auto;
        transform: translateX(0);
        opacity: 1;
        background-color: transparent;
    }
`

const Item = styled.li`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    ${breakpoints.md} {
        margin-right: 55px;
        margin-top: 0;
    }
`

const Link = styled(ScrollLink)`
    width: 100%;
    cursor: pointer;
    font-weight: 600;

    &.active,
    &:hover {
        color: ${cssVariables.colors.primary.light};
    }

    ${breakpoints.md} {
        font-size: ${cssVariables.fonts.medium};
    }
`

const Icon = styled.div`
    ${breakpoints.md} {
        display: none;
    }
`

export { List, Item, Link, Icon }
