import React from "react"

const Mouse = () => {
    return (
        <svg viewBox="0 0 17.573 40.027">
            <g transform="translate(-29 -11)">
                <g>
                    <path
                        d="M48.1,79.293l-3.222,3.222L41.66,79.293a.966.966,0,1,0-1.367,1.367L44.2,84.565a.944.944,0,0,0,1.367,0L49.47,80.66A.966.966,0,0,0,48.1,79.293Z"
                        transform="translate(-7.095 -33.83)"
                        fill="#9ECD5E"
                    />
                    <path
                        d="M49.47,68.293a.944.944,0,0,0-1.367,0l-3.222,3.222L41.66,68.293a.966.966,0,1,0-1.367,1.367L44.2,73.565a.944.944,0,0,0,1.367,0L49.47,69.66A.944.944,0,0,0,49.47,68.293Z"
                        transform="translate(-7.095 -28.2)"
                        fill="#9ECD5E"
                    />
                </g>

                <path
                    d="M49.573,26.573V17.786a8.786,8.786,0,0,0-17.573,0v8.786a8.786,8.786,0,1,0,17.573,0Zm-8.786,6.834a6.854,6.854,0,0,1-6.834-6.834V17.786a6.834,6.834,0,0,1,13.668,0v8.786A6.854,6.854,0,0,1,40.786,33.407Z"
                    transform="translate(-3 2)"
                    fill="#ffffff"
                />
                <path
                    d="M48.976,20a.979.979,0,0,0-.976.976v2.929a.976.976,0,1,0,1.953,0V20.976A.979.979,0,0,0,48.976,20Z"
                    transform="translate(-11.19 -3.63)"
                    fill="#9ECD5E"
                />
            </g>
        </svg>
    )
}

export default Mouse
