import React, { useEffect, useState } from "react"
import * as S from "./styled"
import { cssVariables } from "@cssVariables"

const Messages = ({ formState, errorText, successText }: Props) => {
    const [messagesVisible, setMessagesVisible] = useState(false)

    useEffect(() => {
        const formSubmitted = Object.values(formState).some(
            value => value === true
        )
        if (formSubmitted) {
            setMessagesVisible(true)
        }
    }, [formState])

    return (
        <>
            {messagesVisible && (
                <S.MessageWrapper>
                    {formState.loading && (
                        <S.Loader
                            data-testid={"loader"}
                            background={cssVariables.colors.dark.secondary}
                        />
                    )}

                    {formState.error && (
                        <S.Message
                            data-testid={"error"}
                            color={cssVariables.colors.red.main}
                            dangerouslySetInnerHTML={{
                                __html: errorText,
                            }}
                        />
                    )}
                    {formState.submitted && (
                        <S.Message
                            data-testid={"success"}
                            dangerouslySetInnerHTML={{
                                __html: successText,
                            }}
                        />
                    )}
                </S.MessageWrapper>
            )}
        </>
    )
}
interface Props {
    formState: {
        loading: boolean
        error: boolean
        submitted: boolean
    }
    errorText: string
    successText: string
}

export default Messages
