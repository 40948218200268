import React, { FormEvent, useReducer } from "react"
import Presentation from "./Presentation"

const initialFormState = {
    loading: false,
    error: false,
    submitted: false,
}
const Form = ({
    data: { header, subheader, text, privacy, getform, successtext, errortext },
}: Props) => {
    const formReducer = (_: State, action: Action) => {
        switch (action.type) {
            case "loading":
                return {
                    loading: true,
                    error: false,
                    submitted: false,
                }
            case "submitted":
                return {
                    loading: false,
                    error: false,
                    submitted: true,
                }

            case "error":
                return {
                    loading: false,
                    error: true,
                    submitted: false,
                }
            default:
                throw new Error()
        }
    }

    const [formState, dispatchFormState] = useReducer(
        formReducer,
        initialFormState
    )

    const baseUrl = "https://getform.io/f/"
    const formID = getform

    async function makeFormRequest(form: HTMLFormElement): Promise<Response> {
        return fetch(`${baseUrl}${formID}`, {
            method: "POST",
            body: new FormData(form as HTMLFormElement),
        })
    }

    async function handleOnSubmit(
        event: FormEvent<HTMLFormElement>
    ): Promise<void> {
        try {
            event.preventDefault()
            const form = event.target as HTMLFormElement

            dispatchFormState({ type: "loading" })

            const response = await makeFormRequest(form)
            if (response.ok) {
                dispatchFormState({ type: "submitted" })
            } else {
                dispatchFormState({ type: "error" })
                throw new Error(response.statusText)
            }
        } catch (err) {
            dispatchFormState({ type: "error" })
        }
    }

    return (
        <Presentation
            header={header}
            subheader={subheader}
            text={text}
            privacy={privacy}
            successText={successtext}
            errorText={errortext}
            onSubmit={handleOnSubmit}
            formState={formState}
        />
    )
}

interface State {
    loading: boolean
    error: boolean
    submitted: boolean
}

interface Action {
    type: "loading" | "submitted" | "error"
}

interface Props {
    data: {
        header: string
        subheader: string
        text: string
        privacy: string
        getform: string
        successtext: string
        errortext: string
    }
}

export default Form
