import styled from "styled-components"
import Icon from "@icons/logo-white.svg"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const Footer = styled.footer`
    overflow: hidden;
    background: ${cssVariables.colors.dark.main};
    color: ${cssVariables.colors.primary.white};
`

const Wrapper = styled.div`
    display: grid;

    ${breakpoints.sm} {
        grid-template-columns: repeat(2, 1fr);
    }

    ${breakpoints.md} {
        grid-template-columns: repeat(6, 1fr);
    }
`
const Logo = styled(Icon)`
    margin-top: 60px;
    height: auto;
    width: auto;
    transition: 0.3s ease-in-out;

    ${breakpoints.md} {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
    }
`

const Copyrights = styled.div`
    border-top: 1px solid rgba(218, 218, 218, 0.15);
`

const Paragraph = styled.div`
    padding: 15px 0;

    & p {
        font-size: ${cssVariables.fonts.base};
        font-family: ${cssVariables.fontFamily.primary};
    }

    & a {
        color: ${cssVariables.colors.primary.light};
    }
`

export { Footer, Logo, Wrapper, Copyrights, Paragraph }
