import styled, { css } from "styled-components"
import { cssVariables } from "@cssVariables"

const styles = css<{ disabled?: boolean }>`
    display: flex;
    flex-direction: row;
    width: fit-content;
    color: ${cssVariables.colors.primary.white};
    opacity: ${({ disabled }) => (disabled ? ".4" : "1")};
    font-family: ${cssVariables.fontFamily.primary};
    font-size: ${cssVariables.fonts.medium};
    text-align: left;
    font-weight: 600;
    padding: 15px 30px;
    border-radius: ${cssVariables.borderRadius};
    background: ${({ disabled }) =>
        disabled
            ? cssVariables.colors.gray.dark
            : cssVariables.colors.primary.main};
    background-image: ${({ disabled }) =>
        disabled ? "" : cssVariables.gradient.horizontal};
    background-position: 0 0;
    cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};

    &:hover {
        background-position: 50% 0;
    }

    &:focus {
        outline: none;
    }

    & button:disabled,
    & button[disabled] {
        cursor: no-drop;
    }
`

const Link = styled.a`
    ${styles}
`

const Button = styled.button`
    ${styles}
`

const Icon = styled.div`
    margin-left: 36px;
    margin-top: 9px;
`

export { Button, Link, Icon }
