import React from "react"
import { GridContainer } from "@styled"
import Menu from "./menu/Menu"
import Hamburger from "./hamburger/Hamburger"
import * as S from "./styled"
import { MenuType } from "./types"

const Presentation = ({ scrolled, menuItems, active, setActive }: Props) => {
    return (
        <S.Header scrolled={scrolled}>
            <GridContainer>
                <S.Wrapper>
                    <S.Logo $scrolled={scrolled} />
                    <Menu
                        setActive={() => setActive(false)}
                        active={active}
                        items={menuItems}
                    />
                    <Hamburger
                        active={active}
                        onClick={() => setActive(active => !active)}
                    />
                </S.Wrapper>
            </GridContainer>
        </S.Header>
    )
}

interface Props {
    scrolled: boolean
    menuItems: MenuType
    active: boolean
    setActive: React.Dispatch<React.SetStateAction<boolean>>
}

export default Presentation
