import React from "react"
import * as S from "./styled"
import { Arrow } from "@styled"

const Button = ({ title, url, target, type, disabled, id }: Props) => {
    const renderButton = () => {
        if (url) {
            return (
                <S.Link
                    href={url}
                    rel="noreferrer noopener"
                    target={target ? target : "_self"}
                >
                    {title}
                    <S.Icon>
                        <Arrow />
                    </S.Icon>
                </S.Link>
            )
        }
        return (
            <S.Button
                type={type ? type : "button"}
                disabled={disabled}
                data-testid={id}
            >
                {title}
                <S.Icon>
                    <Arrow />
                </S.Icon>
            </S.Button>
        )
    }
    return renderButton()
}

interface Props {
    title: string
    url?: string
    target?: string
    type?: "button" | "submit" | "reset"
    disabled?: boolean
    id?: string
}

export default Button
