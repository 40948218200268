import React from "react"
import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const {
    colors: { dark, primary },
} = cssVariables

const StyledH1 = styled.h1`
    font-family: ${cssVariables.fontFamily.secondary};
    font-size: ${cssVariables.fonts.large};
    font-weight: 700;
    line-height: 1.3em;

    ${breakpoints.md} {
        font-size: ${cssVariables.fonts.xxLarge};
        line-height: 1.25em;
    }
`

const StyledH2 = styled.h2<{ color: string }>`
    font-size: ${cssVariables.fonts.xLarge};
    color: ${({ color }) => color};
    font-family: ${cssVariables.fontFamily.secondary};
    font-weight: 700;
    line-height: 1;

    ${breakpoints.md} {
        font-size: ${cssVariables.fonts.xxLarge};
        line-height: 1.25em;
    }
`

const StyledH3 = styled.h3<{ color: string; line?: boolean }>`
    position: relative;
    font-size: ${cssVariables.fonts.large};
    font-family: ${cssVariables.fontFamily.secondary};
    font-weight: 700;
    line-height: 1.5em;
    color: ${({ color }) => color};
`
const Line = styled.div<{ length?: number }>`
    position: relative;
    height: 2px;
    background-color: ${cssVariables.colors.primary.main};
    min-width: ${({ length }) => (length ? `${length}px` : "0px")};
    margin-left: 20px;

    &:before {
        content: "";
        position: absolute;
        right: 0;
        height: 14px;
        width: 14px;
        border-radius: 90%;
        background-color: ${cssVariables.colors.primary.main};
        top: -6px;
    }
`

const H3Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`

const StyledH4 = styled.h4<{ color: string }>`
    font-size: ${cssVariables.fonts.small};
    text-transform: uppercase;
    color: ${({ color }) => color};
    margin-bottom: 25px;
    letter-spacing: 0.2em;
    font-weight: 800;
`

const StyledH5 = styled.h5<{ color: string }>`
    font-size: ${cssVariables.fonts.medium};
    font-family: ${cssVariables.fontFamily.secondary};
    text-transform: uppercase;
    color: ${({ color }) => color};
    letter-spacing: 0.2em;
    font-weight: 800;
`

const H1 = ({ content }: { content: string }) => {
    return <StyledH1 dangerouslySetInnerHTML={{ __html: content }} />
}

const H2 = ({ content, color = "#000" }: Props) => {
    return (
        <StyledH2 color={color} dangerouslySetInnerHTML={{ __html: content }} />
    )
}

const H3 = ({ line, content, color = dark.main }: H3props) => {
    return (
        <H3Wrapper>
            <StyledH3
                color={color}
                dangerouslySetInnerHTML={{ __html: content }}
            />
            {line && <Line length={line} />}
        </H3Wrapper>
    )
}

const H4 = ({ content, color = primary.main }: Props) => {
    return (
        <StyledH4 color={color} dangerouslySetInnerHTML={{ __html: content }} />
    )
}

const H5 = ({ content, color = primary.secondary }: Props) => {
    return (
        <StyledH5 color={color} dangerouslySetInnerHTML={{ __html: content }} />
    )
}

interface Props {
    content: string
    color?: string
}

interface H3props extends Props {
    line?: number
}

export { H1, H2, H3, H4, H5 }
