import styled from "styled-components"
import Icon from "@icons/logo.svg"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const Header = styled.header<{ scrolled: boolean }>`
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    font-family: ${cssVariables.fontFamily.secondary};
    background: #fff;
    z-index: 200;
    transition: 0.3s ease-in-out;

    ${breakpoints.md} {
        height: ${({ scrolled }) => (scrolled ? "100px" : "200px")};
        background: ${({ scrolled }) => (scrolled ? "#fff" : "transparent")};
        color: ${({ scrolled }) => (scrolled ? "#000" : "#fff")};
    }
`

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Logo = styled(Icon)`
    height: 60px;
    width: auto;
    transition: 0.3s ease-in-out;

    ${breakpoints.md} {
        height: ${({ $scrolled }) => ($scrolled ? "70px" : "110px")};
        width: auto;
    }
`

const Hamburger = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    ${breakpoints.sm} {
        display: none;
    }
`

export { Header, Logo, Hamburger, Wrapper }
