import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"
import styled from "styled-components"

const Wrapper = styled.div<{ position: Position }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: ${({ position }) =>
        position === "center" ? "center" : "flex-start"};
    margin-bottom: 35px;
    padding-top: 25px;

    ${breakpoints.md} {
        padding-top: 30px;
        margin-bottom: 45px;
    }
`

const Headers = styled.div<{ position: Position }>`
    text-align: ${({ position }) => position};
    z-index: 10;
`

const Background = styled.span<Props>`
    position: absolute;
    left: ${({ position }) => (position === "center" ? "unset" : "-3px")};
    top: 0;
    font-size: 80px;
    font-family: ${cssVariables.fontFamily.secondary};
    font-weight: 700;
    //https://stackoverflow.com/questions/2570972/css-font-border
    text-shadow: ${({ shadowColor }) => `
    1px 1px 0 ${shadowColor}, -1px 1px 0 ${shadowColor}, 1px -1px 0 ${shadowColor},
    -1px -1px 0 ${shadowColor}, 0px 1px 0 ${shadowColor}, 0px -1px 0 ${shadowColor}, -1px 0px 0 ${shadowColor},
    1px 0px 0 ${shadowColor}, 2px 2px 0 ${shadowColor}, -2px 2px 0 ${shadowColor}, 2px -2px 0 ${shadowColor},
    -2px -2px 0 ${shadowColor}, 0px 2px 0 ${shadowColor}, 0px -2px 0 ${shadowColor}, -2px 0px 0 ${shadowColor},
    2px 0px 0 ${shadowColor}, 1px 2px 0 ${shadowColor}, -1px 2px 0 ${shadowColor}, 1px -2px 0 ${shadowColor},
    -1px -2px 0 ${shadowColor}, 2px 1px 0 ${shadowColor}, -2px 1px 0 ${shadowColor}, 2px -1px 0 ${shadowColor},
    -2px -1px 0 ${shadowColor}
    `};
    line-height: 0.75;
    color: ${({ light, color }) =>
        color ? color : light ? cssVariables.colors.dark.secondary : "#fff"};
    opacity: 0.15;

    ${breakpoints.md} {
        left: ${({ position, $offset }) =>
            position === "center" ? "unset" : $offset ? "-20px" : "0"};
        text-indent: -10px;
        letter-spacing: 5px;
        font-size: 150px;
        bottom: 10px;
    }

    ${breakpoints.lg} {
        left: ${({ position, $offset }) =>
            position === "center" ? "unset" : $offset ? "-40px" : "0"};
    }
`

type Position = "center" | "left"
interface Props {
    light?: true
    position: Position
    shadowColor: string
    $offset?: true
    color?: string
}

export { Wrapper, Background, Headers }
