import { cssVariables } from "@cssVariables"
import React from "react"

const Arrow = ({ color = cssVariables.colors.primary.light }: Props) => {
    return (
        <svg width="11.149" height="11.818" viewBox="0 0 11.149 11.818">
            <g transform="translate(1 1.409)">
                <path
                    d="M7.337,3.078l3.812,4.5h0l-3.812,4.5"
                    transform="translate(-2 -3.078)"
                    fill="none"
                    stroke={color}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
            </g>
        </svg>
    )
}

interface Props {
    color?: string
}

export default Arrow
