const colors: {
    primary: {
        main: "#306646"
        secondary: "#2E8953"
        light: "#9ECD5E"
        dark: "#183323"
        white: "#FFFFFF"
    }
    dark: { main: "#0D0D0D"; secondary: "#1A1A1A"; light: "#A3A3A3" }
    gray: {
        main: "#DADADA"
        light: "#F8F8F8"
        dark: "#4B4B4B"
        medium: "#DCE8E4"
    }
    red: { main: "#FF0033" }
} = {
    primary: {
        main: "#306646",
        secondary: "#2E8953",
        light: "#9ECD5E",
        dark: "#183323",
        white: "#FFFFFF",
    },
    dark: {
        main: "#0D0D0D",
        secondary: "#1A1A1A",
        light: "#A3A3A3",
    },
    gray: {
        main: "#DADADA",
        light: "#F8F8F8",
        dark: "#4B4B4B",
        medium: "#DCE8E4",
    },
    red: {
        main: "#FF0033",
    },
}
const fonts: {
    xSmall: "10px"
    small: "12px"
    base: "14px"
    medium: "16px"
    large: "20px"
    xLarge: "30px"
    xxLarge: "38px"
} = {
    xSmall: "10px",
    small: "12px",
    base: "14px",
    medium: "16px",
    large: "20px",
    xLarge: "30px",
    xxLarge: "38px",
}
const gradient = {
    horizontal: `linear-gradient(to right, ${colors.primary.main}, ${colors.primary.dark},${colors.primary.main},${colors.primary.main}); background-size:300%, 100%`,
    horizontalGray: `linear-gradient(to right, ${colors.gray.main}, ${colors.gray.dark},${colors.gray.main},${colors.gray.main}); background-size:300%, 100%`,
    vertical: `linear-gradient(to bottom, ${colors.primary.main}, ${colors.primary.dark})`,
}

export const cssVariables = {
    colors: {
        ...colors,
    },
    gradient: {
        ...gradient,
    },
    fonts: {
        ...fonts,
    },
    gridWidth: "1440px",
    hdWidth: "1920px",
    borderRadius: "30px",
    boxShadow: "0px 0px 50px 0px rgba(2,27,15,0.6)",
    fontFamily: {
        primary: "'Nunito Sans', sans-serif",
        secondary: "'Quicksand', sans-serif",
    },
}
