import React from "react"
import * as S from "./styled"

const Hamburger = ({ active, onClick }: props) => {
    return (
        <S.Wrapper onClick={onClick}>
            <S.Line active={active} />
            <S.Line active={active} />
            <S.Line active={active} />
            <S.Line active={active} />
            <S.Caption>menu</S.Caption>
        </S.Wrapper>
    )
}

export default Hamburger

interface props {
    active: boolean
    onClick: () => void
}
