import React from "react"
import { useOptionsQuery } from "@hooks"
import Presentation from "./Presentation"

const Footer = () => {
    const { id, footermenu, contact, address, socials, form, copyrights } =
        useOptionsQuery().optionsFooter.acf

    return (
        <Presentation
            id={id}
            footermenu={footermenu}
            contact={contact}
            address={address}
            socials={socials}
            form={form}
            copyrights={copyrights}
        />
    )
}

export default Footer
