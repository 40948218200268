import React, { useState, useEffect } from "react"
import throttle from "lodash.throttle"
import { useOptionsQuery } from "@hooks"
import Presentation from "./Presentation"

const openMenuBodyClass = "menu-open"

const Header = () => {
    const [active, setActive] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)
    const menuItems = useOptionsQuery().optionsHeader.acf.menu

    useEffect(() => {
        const calcHeight = throttle(() => {
            setScrollPosition(window.pageYOffset)
        }, 500)
        window.addEventListener("scroll", calcHeight)
        calcHeight()
        return () => {
            window.removeEventListener("scroll", calcHeight)
        }
    }, [])

    useEffect(() => {
        if (active) {
            document.body.classList.add(openMenuBodyClass)
        } else {
            document.body.classList.remove(openMenuBodyClass)
        }
    }, [active])

    return (
        <Presentation
            active={active}
            scrolled={scrollPosition > 0}
            setActive={setActive}
            menuItems={menuItems}
        />
    )
}

export default Header
