import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const Input = styled.div`
    width: calc(50% - 5px);

    display: flex;
    flex-direction: column;

    ${breakpoints.md} {
        width: calc(50% - 13px);
    }

    &:nth-child(even) {
        margin-left: 10px;

        ${breakpoints.md} {
            margin-left: 25px;
        }
    }

    & label {
    }

    & input {
        background: transparent;
        border: 1px solid ${cssVariables.colors.gray.dark};
        color: ${cssVariables.colors.primary.white};
        border-radius: 15px;
        min-height: 40px;
        padding-left: 15px;
        outline: none;

        &:placeholder {
            color: ${cssVariables.colors.gray.main};
            opacity: 1;
        }

        &:focus {
            background: ${cssVariables.colors.dark.main};
        }

        &:-webkit-autofill,
        &:focus:-webkit-autofill {
            background-color: transparent !important;
            -webkit-box-shadow: 0 0 0 50px ${cssVariables.colors.dark.secondary}
                inset;
            -webkit-text-fill-color: ${cssVariables.colors.primary.white};
        }
    }
`

export { Input }
