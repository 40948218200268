import React from "react"
import * as S from "./styled"
import { Facebook, Instagram, Linkedin } from "../../../../assets/socials"

const Socials = ({ items }: Props) => {
    const getIcon = (icon: string) => {
        switch (icon) {
            case "fb":
                return <Facebook />
            case "ig":
                return <Instagram />
            case "in":
                return <Linkedin />
            default:
                return <Facebook />
        }
    }
    return (
        <S.Wrapper>
            {items?.map(({ icon, link }, index) => (
                <S.Item key={index}>
                    <S.Link
                        role="link"
                        target={link.target}
                        rel="noopener noreferrer"
                        href={link.url}
                    >
                        <S.Icon>{getIcon(icon)}</S.Icon>
                        {link.title}
                    </S.Link>
                </S.Item>
            ))}
        </S.Wrapper>
    )
}

interface Props {
    items: Array<{
        icon: string
        link: {
            target: string
            title: string
            url: string
        }
    }>
}

export default Socials
