import { graphql, useStaticQuery } from "gatsby"

const useOptionsQuery = () => {
    const data: Data = useStaticQuery(
        graphql`
            query {
                wp {
                    optionsheader {
                        acf {
                            menu {
                                idToScroll
                                text
                            }
                        }
                        pageTitle
                    }
                    optionsfooter {
                        acf {
                            id
                            contact {
                                email
                                name
                                title
                                image {
                                    ...gatsbyFluidImage
                                }
                            }
                            address {
                                content
                                title
                            }
                            footermenu {
                                title
                                items {
                                    page {
                                        target
                                        title
                                        url
                                    }
                                }
                            }
                            socials {
                                icon
                                link {
                                    target
                                    title
                                    url
                                }
                            }
                            form {
                                header
                                subheader
                                text
                                privacy
                                getform
                                successtext
                                errortext
                            }
                            copyrights
                        }
                        pageTitle
                    }
                    page404 {
                        acf {
                            header
                            button {
                                title
                                url
                                target
                            }
                            image {
                                ...gatsbyFluidImage
                            }
                        }
                    }
                }
            }
        `
    )
    const { optionsheader, optionsfooter, page404 } = data.wp
    return {
        optionsHeader: optionsheader,
        optionsFooter: optionsfooter,
        page404: page404,
    }
}

export default useOptionsQuery

interface Data {
    wp: {
        optionsheader: {
            acf: {
                menu: Array<{
                    idToScroll: any
                    text: any
                }>
            }
            pageTitle: string
        }
        optionsfooter: {
            acf: {
                id: string
                contact: {
                    email: string
                    name: string
                    title: string
                    image: any
                }
                address: Array<{
                    content: string
                    title: string
                }>
                footermenu: {
                    title: string
                    items: Array<{
                        page: {
                            title: string
                            url: string
                            target: string
                        }
                    }>
                }
                socials: Array<{
                    icon: string
                    link: {
                        target: string
                        title: string
                        url: string
                    }
                }>
                form: {
                    header: string
                    subheader: string
                    text: string
                    privacy: string
                    getform: string
                    successtext: string
                    errortext: string
                }
                copyrights: string
            }
            pageTitle: string
        }
        page404: {
            acf: {
                header: string
                button: {
                    title: string
                    url: string
                    target: string
                }
                image: any
            }
        }
    }
}
