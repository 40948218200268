import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const Wrapper = styled.div`
    margin: 40px 0;
    display: flex;
    flex-direction: row;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 6;

    ${breakpoints.md} {
        margin-top: 120px;
        grid-column-start: 1;
        grid-column-end: 5;
        grid-row-start: 4;
        grid-row-end: 5;
    }
`

const Icon = styled.div`
    margin-right: 15px;
    margin-top: 5px;

    &:before {
        content: ;
    }
`
const Item = styled.div`
    margin-right: 38px;
    font-family: ${cssVariables.fontFamily.primary};
    font-size: ${cssVariables.fonts.medium};

    &:hover {
        opacity: 0.7;

        & ${Icon} {
            opacity: 0.7;
        }
    }
`
const Link = styled.a`
    display: flex;
    flex-direction: row;
`

export { Wrapper, Item, Link, Icon }
