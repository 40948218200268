import React, { useEffect, useState } from "react"
import * as S from "./styled"
import { Button, Heading } from "@common"
import Input from "./input/Input"
import Messages from "./Messages/Messages"

const Presentation = ({
    header,
    subheader,
    text,
    privacy,
    successText,
    errorText,
    onSubmit,
    formState,
}: Props) => {
    const [formVisible, setFormVisible] = useState(true)

    useEffect(() => {
        if (formState.loading || formState.error || formState.submitted) {
            setFormVisible(false)
        }
    }, [formState])

    return (
        <S.Wrapper>
            <Heading
                light
                position="left"
                backgroundText={""}
                subheader={subheader}
                header={header}
            />
            <S.Paragraph
                dangerouslySetInnerHTML={{
                    __html: text,
                }}
            />

            {formVisible && (
                <S.Form data-testid={"form"} onSubmit={onSubmit}>
                    <S.Group>
                        <Input
                            type={"text"}
                            name={"Imię"}
                            required
                            minLength={3}
                            placeholder={"Imię"}
                            label={"Imię*"}
                            id={"name"}
                        />
                        <Input
                            type={"text"}
                            name={"Nazwisko"}
                            required
                            minLength={3}
                            placeholder={"Nazwisko"}
                            label={"Nazwisko*"}
                            id={"surname"}
                        />
                    </S.Group>
                    <S.Group>
                        <Input
                            type={"email"}
                            name={"Email"}
                            minLength={9}
                            placeholder={"Email"}
                            label={"Email"}
                        />
                        <Input
                            type={"tel"}
                            name={"Telefon"}
                            placeholder={"Numer telefonu"}
                            label={"Numer telefonu"}
                        />
                    </S.Group>

                    <S.Message>
                        <label>Wiadomość</label>
                        <textarea name="Wiadomość" placeholder="Wiadomość" />
                    </S.Message>

                    <S.Group>
                        <S.Privacy
                            dangerouslySetInnerHTML={{
                                __html: privacy,
                            }}
                        />
                        <Button
                            type={"submit"}
                            title={"Wyślij"}
                            id={"submit"}
                        />
                    </S.Group>
                </S.Form>
            )}
            <Messages
                formState={formState}
                errorText={errorText}
                successText={successText}
            />
        </S.Wrapper>
    )
}

interface Props {
    header: string
    subheader: string
    text: string
    privacy: string
    successText: string
    errorText: string
    onSubmit: any
    formState: {
        loading: boolean
        error: boolean
        submitted: boolean
    }
}

export default Presentation
