import React from "react"
import * as S from "./styled"
import { MenuType } from "../types"
import { Arrow } from "@styled"

const Menu = ({ items, active, setActive }: Props) => {
    const linkProps = {
        activeClass: "active",
        spy: true,
        smooth: true,
        offset: -150,
        duration: 500,
    }
    return (
        <S.List active={active}>
            {items?.map(({ idToScroll, text }, index) => (
                <S.Item key={index}>
                    <S.Link onClick={setActive} to={idToScroll} {...linkProps}>
                        {text}
                    </S.Link>
                    <S.Icon>
                        <Arrow />
                    </S.Icon>
                </S.Item>
            ))}
        </S.List>
    )
}

interface Props {
    items: MenuType
    active: boolean
    setActive: () => void
}

export default Menu
