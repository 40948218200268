import React from "react"
import * as S from "./styled"
import { H5 } from "@headers"
import { cssVariables } from "@cssVariables"

const Menu = ({ title, items }: Props) => {
    const linkProps = {
        activeClass: "active",
        spy: true,
        smooth: true,
        offset: -150,
        duration: 500,
    }
    return (
        <S.List>
            <H5 content={title} color={cssVariables.colors.primary.secondary} />

            {items?.map(({ page: { title, url, target } }, index) => (
                <S.Item key={index}>
                    {target === "_blank" && (
                        <S.ExternalLink href={url} target={target}>
                            {title}
                        </S.ExternalLink>
                    )}
                    {target === "" && (
                        <S.Link to={url} {...linkProps}>
                            {title}
                        </S.Link>
                    )}
                </S.Item>
            ))}
        </S.List>
    )
}

interface Props {
    title: string
    items: Array<{
        page: {
            title: string
            url: string
            target: string
        }
    }>
}

export default Menu
