import React from "react"
import Header from "./header/Header"
import Footer from "./footer/Footer"
const Template = ({ children }: Props) => {
    return (
        <main>
            <Header />
            {children}
            <Footer />
        </main>
    )
}

interface Props {
    children: React.ReactNode
}

export default Template
